const tableOfContent = {
    initialize: function (tocValue) {
        document.addEventListener('DOMContentLoaded', function () {
            if (tocValue === "1") {
                var ckContent = document.querySelector('.ck-content');
                var mainTitle = ckContent.querySelector('h1');
                var headings = ckContent.querySelectorAll('h2');
                headings.forEach(function (heading, index) {
                    heading.id = 'section' + (index + 1);
                });
                var tocIcon = document.getElementById('toc-icon');
                var tocContainer = document.getElementById('toc');
                var tocDiv = document.getElementById('toc-container');
                if (tocDiv) {
                    mainTitle.parentNode.insertBefore(tocDiv, mainTitle.nextSibling);
                }
                tocIcon.addEventListener('click', function () {
                    if (tocContainer.classList.contains('show')) {
                        tocContainer.classList.remove('show');
                        tocIcon.classList.remove('fa-xmark');
                        tocIcon.classList.add('fa-table-list');
                    } else {
                        tocContainer.classList.add('show');
                        tocIcon.classList.remove('fa-table-list');
                        tocIcon.classList.add('fa-xmark');
                    }
                });
            }
        });
    }
};
export default tableOfContent;