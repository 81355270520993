import './bootstrap';// Include Bootstrap Select JS


//import '../../node_modules/font-awesome/css/font-awesome.css'; // Include Font
import '../../public/icons/fontawesome/css/all.min.css';
import '../../public/frontend/css/system/style.min.css';
import '../../public/frontend/css/youtube/embed.min.css';
import '../../public/frontend/css/ckeditor/ckeditor.min.css';
import '../../public/frontend/css/fonts.css';
//import '../../public/frontend/css/flipbook.style.css';
import '../../node_modules/waypoints/src/waypoint';
import '../../public/frontend/js/dz.carousel.js';
import '../../public/frontend/js/dz.ajax.js';
import '../../public/frontend/js/custom.js';
import '../../public/frontend/js/embed.js';
import tableOfContent from '../../public/frontend/js/toc.js';
window.tableOfContent = tableOfContent;